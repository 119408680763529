.col.project-content {
  font-size: 1.05rem;
  padding-top: 5px;
  padding-right: 20%; }

.project-name {
  font-family: 'inconsolata';
  font-weight: 500;
  padding-bottom: .25rem;
  letter-spacing: 1px;
  font-size: 1.25rem;
  color: #3262CA; }

.project-underline-container {
  position: relative;
  cursor: pointer; }

.project-underline {
  content: '';
  position: absolute;
  width: 100%;
  height: 60%;
  top: 50%;
  left: 5%;
  background-color: #fbe38e;
  z-index: -1;
  transition: 200ms; }

.project-underline-container:hover .project-underline {
  top: 45%;
  left: 3%;
  transition: 200ms; }

.project-mockup {
  width: 75%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.tech-item {
  display: inline-block;
  margin: 3px 6px 3px 0;
  font-family: 'inconsolata';
  font-size: .75rem;
  font-weight: 500;
  padding: 2px 7px;
  border-radius: 3px;
  color: #1e53c5;
  border: 1px solid #1e53c5;
  background: 0 0; }

@media screen and (max-width: 1200px) {
  .project-content {
    font-size: .95rem; } }

@media screen and (max-width: 800px) {
  .project-content {
    font-size: .85rem; }
  .small-text {
    display: none; } }

.link:hover {
  color: #fbe38e !important; }
