.col.experience-content {
  font-size: 1.05rem;
  padding-top: 5px;
  padding-right: 20%; }

.experience-name {
  font-weight: 500;
  padding-bottom: .25rem;
  letter-spacing: 1px; }

.experience-title {
  font-family: 'inconsolata';
  font-weight: 500;
  padding-bottom: .25rem;
  letter-spacing: 1px;
  font-size: 1.25rem;
  color: #3262CA;
  cursor: pointer; }

.experience-underline-container {
  position: relative; }

.experience-underline {
  content: '';
  position: absolute;
  width: 100%;
  height: 60%;
  top: 50%;
  left: 5%;
  background-color: #fbe38e;
  z-index: -1;
  transition: 200ms; }

.experience-title:hover .experience-underline {
  top: 45%;
  left: 3%;
  transition: 200ms; }

.collapse-content {
  padding-left: 3px; }

.triangle-container {
  padding-right: 10px; }

.rotate-triangle {
  display: inline-block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

@media screen and (max-width: 1200px) {
  .experience-content {
    font-size: .95rem; } }

@media screen and (max-width: 800px) {
  .experience-content {
    font-size: .85rem; } }
