.col.about-content {
  font-size: 1.05rem;
  padding-top: 5px;
  padding-right: 20%; }

@media screen and (max-width: 1200px) {
  .about-content {
    font-size: .95rem; } }

@media screen and (max-width: 800px) {
  .about-content {
    font-size: .85rem; } }
