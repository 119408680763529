.contact-underline-container {
  position: relative;
  z-index: 2; }

.contact-link {
  font-family: 'inconsolata';
  font-weight: 700;
  padding-bottom: .25rem;
  letter-spacing: 1px;
  font-size: 1.25rem;
  color: #3262CA !important;
  cursor: pointer; }

.contact-underline {
  content: '';
  position: absolute;
  width: 100%;
  height: 60%;
  top: 50%;
  left: 5%;
  background-color: #69e9aa;
  z-index: -1;
  transition: 200ms; }

.contact-link:hover {
  text-decoration: none; }

.contact-underline-container:hover .contact-underline {
  top: 35%;
  left: 1%;
  transition: 200ms; }

.credits {
  color: #69e9aa;
  font-size: .65rem;
  letter-spacing: 1.65px; }

.sam-photo {
  max-width: 250px;
  height: 250px;
  border-radius: 50%; }

@media screen and (max-width: 1200px) {
  .contact-link {
    font-size: 1.25rem; } }

@media screen and (max-width: 800px) {
  .contact-link {
    font-size: .95rem; }
  .contact-underline-container {
    margin-left: 40px; } }
