.skills-content {
  font-size: 1.05rem;
  padding-top: 5px; }

@media screen and (max-width: 1200px) {
  .skills-content {
    font-size: .95rem; } }

@media screen and (max-width: 800px) {
  .skills-content {
    font-size: .85rem; } }

.skills-category {
  font-weight: 700;
  padding-bottom: .25rem;
  letter-spacing: 1px; }

.skill {
  padding-bottom: .15rem; }
