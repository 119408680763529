.intro {
  height: 100vh;
  position: relative; }

.green {
  color: #69e9aa; }

.icon-link:hover {
  color: #69e9aa !important; }

.intro-title {
  font-size: 3.5rem;
  font-weight: 700; }

.intro-body {
  font-size: 1.5rem;
  word-wrap: normal; }

.intro-contact {
  font-size: 1.25rem; }

@media screen and (max-width: 1200px) {
  .intro-title {
    font-size: 3rem; }
  .intro-body {
    font-size: 1.25rem; }
  .intro-contact {
    font-size: 1rem; } }

@media screen and (max-width: 800px) {
  .intro-title {
    font-size: 2.5rem; }
  .intro-body {
    width: 75%;
    /* The width is 100%, when the viewport is 800px or smaller */
    font-size: 1rem; }
  .intro-contact {
    font-size: .85rem; }
  .left-spacer {
    display: none; } }

a {
  color: inherit !important; }

.scroll-indicator {
  position: absolute;
  bottom: 5%;
  left: 50%;
  opacity: .15;
  transition: 600ms; }

.hide {
  opacity: 0;
  transition: 600ms; }

.typeout {
  padding-right: 3px;
  padding-left: 2px;
  letter-spacing: .05em;
  /* Adjust as needed */ }

.cursor {
  color: #fbe38e;
  font-size: 1.7rem;
  line-height: 80%;
  font-weight: 900;
  -webkit-animation: blink-caret .75s step-end infinite;
          animation: blink-caret .75s step-end infinite; }

/* The typewriter cursor effect */
@-webkit-keyframes blink-caret {
  from, to {
    color: transparent; }
  50% {
    color: #fbe38e; } }
@keyframes blink-caret {
  from, to {
    color: transparent; }
  50% {
    color: #fbe38e; } }
